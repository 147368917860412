import React from 'react';
import { Combobox } from "./Combobox";
import { fetchWrapper } from "../FetchWrapper";
import AuthService from "../AuthService";

import { EditorHodnotyForm } from "./EditorHodnotyForm";

import { FormControlLabel, Checkbox } from '@mui/material';

import Global from "./Global";
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from './Loader/LoadingSpinner';


export class ZakazniciForm extends React.Component {
    constructor(props) {
        super(props);

       
        if (props.drZakaznik) this.state = {
            seza_id: props.drZakaznik.seza_id,
            seza_jmeno: props.drZakaznik.seza_jmeno,
            seza_ulice: props.drZakaznik.seza_ulice,
            seza_mesto: props.drZakaznik.seza_mesto,
            seza_psc: props.drZakaznik.seza_psc,

            seza_po: props.drZakaznik.seza_po,
            seza_ic: props.drZakaznik.seza_ic,
            seza_dic: props.drZakaznik.seza_dic,
            seza_kontakt: props.drZakaznik.seza_kontakt,
                        
            seza_telefon: props.drZakaznik.seza_telefon,
            seza_email: props.drZakaznik.seza_email,

            seza_poznamka: props.drZakaznik.seza_poznamka,
            seza_datumpridani: props.drZakaznik.seza_datumpridani,
            seza_zruseno: props.drZakaznik.seza_zruseno,

            bNovy: false
        };
        else this.state = {
            seza_id: -1,
            seza_jmeno: "",
            seza_ulice: "",
            seza_mesto: "",
            seza_psc: "",

            seza_po: false,
            seza_ic: "",
            seza_dic: "",
            seza_kontakt: "",

            seza_telefon: "",
            seza_email: "",

            seza_poznamka: "",
            seza_datumpridani: Global.toISOString(new Date()),
            seza_zruseno: false,
            

            bNovy: true
        };

        this.formSubmit = this.formSubmit.bind(this);
        this.zavritDialog = this.zavritDialog.bind(this);
        /*this.inicializace = this.inicializace.bind(this);
        this.reloadData = this.reloadData.bind(this);*/

    }

    /*componentDidMount() {
        this.inicializace();

        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }

    reloadData() {
        this.inicializace();
    }

    inicializace() {

        this.setState({ loading: true });
        fetchWrapper.get(fetchWrapper.VratZakazniky())
            .then(data => {

            })
            .catch(error => {
                this.setState({ loading: false });

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst číselník: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }*/

    formSubmit(event) {
        event.preventDefault();


        let chyba = "";
        if (this.state.seza_jmeno == "") chyba = chyba + " Nebylo nastaven jméno.";
        if (this.state.seza_ulice == "" || this.state.seza_mesto == "" || this.state.seza_psc == "") chyba = chyba + " Nebyla nastavena adresa.";
        if (this.state.seza_jmeno == "") chyba = chyba + " Nebylo nastaven jméno.";


        if (chyba != "") {
            MessageBox.open({
                title: "Chyba",
                content: <p>{chyba}</p>,
                buttons: [
                    { name: "OK", handler: () => "OK" }
                ]
            });

            return;
        }

       
        const zakData = {
            seza_id: this.state.seza_id,
            seza_jmeno: this.state.seza_jmeno,
            seza_ulice: this.state.seza_ulice,
            seza_mesto: this.state.seza_mesto,
            seza_psc: this.state.seza_psc,
            seza_telefon: this.state.seza_telefon,
            seza_email: this.state.seza_email,

            seza_po: this.state.seza_po,
            seza_ic: this.state.seza_ic,
            seza_dic: this.state.seza_dic,
            seza_kontakt: this.state.seza_kontakt,

            seza_zruseno: this.state.seza_zruseno,
            seza_poznamka: this.state.seza_poznamka,
            seza_datumpridani: this.state.seza_datumpridani
        };

        LoadingSpinner.open();
        if (this.state.seza_id > 0) {
            fetchWrapper.put(fetchWrapper.VratZakazniky(), zakData).then(data => {
                LoadingSpinner.close();

                this.props.drZakaznik.seza_jmeno = this.state.seza_jmeno;
                this.props.drZakaznik.seza_ulice = this.state.seza_ulice;
                this.props.drZakaznik.seza_mesto = this.state.seza_mesto;
                this.props.drZakaznik.seza_psc = this.state.seza_psc;
                
                this.props.drZakaznik.seza_po = this.state.seza_po;
                this.props.drZakaznik.seza_ic = this.state.seza_ic;
                this.props.drZakaznik.seza_dic = this.state.seza_dic;
                this.props.drZakaznik.seza_kontakt = this.state.seza_kontakt;
                this.props.drZakaznik.seza_kontakt = this.state.seza_kontakt;
                
                this.props.drZakaznik.seza_telefon = this.state.seza_telefon;
                this.props.drZakaznik.seza_email = this.state.seza_email;
                this.props.drZakaznik.seza_poznamka = this.state.seza_poznamka;
                this.props.drZakaznik.seza_zruseno = this.state.seza_zruseno;

                this.props.zavritDialog(true); 
            }).catch(error => {
                LoadingSpinner.close();
                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se uložit data: {error.message}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
        }
        else {
            fetchWrapper.post(fetchWrapper.VratZakazniky(), zakData).then(data => {
                LoadingSpinner.close(); this.props.zavritDialog(true);
            })
                .catch(error => {
                LoadingSpinner.close();
                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se uložit data: {error.message}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
        }
    }

    zavritDialog() {
        this.props.zavritDialog(false);
    }



    render() {
        const nadpis = (this.state.bNovy ? "Nový zákazník" : "Upravit zákazníka");

        return (
            <form onSubmit={this.formSubmit}>
                <h1>{nadpis}</h1>

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Jméno:" hodnota={this.state.seza_jmeno} widthLeft={120} stylesRight={{maxWidth: 300}}  typ="TEXT" onChange={e => this.setState({ seza_jmeno: e.target.value })} />
                </div>
                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Ulice:" hodnota={this.state.seza_ulice} widthLeft={120} stylesRight={{ maxWidth: 300 }} typ="TEXT" onChange={e => this.setState({ seza_ulice: e.target.value })} />
                </div>                

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="Město:" hodnota={this.state.seza_mesto} widthLeft={120} stylesRight={{ maxWidth: 300 }} typ="TEXT" onChange={e => this.setState({ seza_mesto: e.target.value })} />
                </div>

                <div className="form_udaj_container">
                    <EditorHodnotyForm popis="PSČ:" hodnota={this.state.seza_psc} widthLeft={120} stylesRight={{ maxWidth: 80 }} typ="TEXT" onChange={e => this.setState({ seza_psc: e.target.value })} />
                </div>

                <div className="odsazeni_bottom10 odsazeni_top10"> 
                    <div className="form_udaj_container">
                        <EditorHodnotyForm popis="Email:" hodnota={this.state.seza_email} widthLeft={120} stylesRight={{ maxWidth: 300 }} typ="TEXT" onChange={e => this.setState({ seza_email: e.target.value })} />
                    </div>
                    <div className="form_udaj_container">
                        <EditorHodnotyForm popis="Telefon:" hodnota={this.state.seza_telefon} widthLeft={120} stylesRight={{ maxWidth: 300 }} typ="TEXT" onChange={e => this.setState({ seza_telefon: e.target.value })} />
                    </div>
                </div>

                <div className="odsazeni_bottom10 odsazeni_top10">                    
                    <div className="form_udaj_container">
                        <EditorHodnotyForm popis="Právnická osoba:" hodnota={this.state.seza_po} widthLeft={120} stylesRight={{ maxWidth: 50 }} typ="CHECKBOX" onChange={e => this.setState({ seza_po: e.target.checked ? true : false })} />
                    </div>
                    {this.state.seza_po &&
                        <div>
                            <div className="form_udaj_container">
                                <EditorHodnotyForm popis="IČ:" hodnota={this.state.seza_ic} widthLeft={120} stylesRight={{ maxWidth: 200 }} typ="TEXT" onChange={e => this.setState({ seza_ic: e.target.value })} />
                            </div>
                            <div className="form_udaj_container">
                                <EditorHodnotyForm popis="DIČ:" hodnota={this.state.seza_dic} widthLeft={120} stylesRight={{ maxWidth: 200 }} typ="TEXT" onChange={e => this.setState({ seza_dic: e.target.value })} />
                            </div>
                            <div className="form_udaj_container">
                                <EditorHodnotyForm popis="Kontakt:" hodnota={this.state.seza_kontakt} widthLeft={120} stylesRight={{ maxWidth: 300 }} typ="TEXT" onChange={e => this.setState({ seza_kontakt: e.target.value })} />
                            </div>
                        </div>
                    }
                </div>

                <div className="odsazeni_top10">     
                    <div className="form_udaj_container">
                        <EditorHodnotyForm popis="Poznámka:" hodnota={this.state.seza_poznamka} widthLeft={120} stylesRight={{ maxWidth: 500 }} typ="TEXTAREA" onChange={e => this.setState({ seza_poznamka: e.target.value })} />
                    </div>
                    {!this.state.bNovy && <div className="form_udaj_container">
                        <EditorHodnotyForm popis="Zrušen:" hodnota={this.state.seza_zruseno} widthLeft={120} stylesRight={{ maxWidth: 50 }} typ="CHECKBOX" onChange={e => this.setState({ seza_zruseno: e.target.checked ? true : false })} />
                    </div>}
                </div>


                <div className="form_udaj_container">
                    <div className="left" style={{ width: 120 }}> </div>
                    <div className="right">
                        <button type="button" className="form_button" style={{ marginLeft: 7 }} onClick={this.zavritDialog}>Zpět</button>
                        <button type="submit" className="form_button" style={{ marginLeft: 7 }}>Uložit</button>
                    </div>
                </div>
            </form>
        );
    }
}
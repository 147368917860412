import React, { useState, useEffect } from 'react';
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";
import AuthService from "./AuthService";
import { FormLogin } from './components/FormLogin';
import './custom.css';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';


import { PrivateRoute, DefaultRoute } from "./PrivateRoute";

import MessageBox from "./components/MessageBox/MessageBox";
import { Home } from "./components/Home";
import { ZakazniciList } from "./components/ZakazniciList";
import { ZarizeniList } from "./components/ZarizeniList";
import { ZarizeniNahled } from "./components/ZarizeniNahled";
import { MapaInstalaci } from "./components/MapaInstalaci";


import { Header } from './components/Header';
import { Footer } from './components/Footer';
import Navbar from './components/Menu/Navbar';


function App() {
    const navigate = useNavigate();

    const logout = () => {
        AuthService.logout();
        navigate("/");
    }


    return (
        <div className="container_12">
            <Header title="Servis" logout={logout} />
            <div className="clear" />
            <Navbar />
            <div className="clear" />
            <main>
                <Routes>
                    <Route key={true} exact path='/' element={<PrivateRoute />}>
                        <Route key={true} exact path='/' element={<Home navigate={navigate} />} />
                    </Route>

                    <Route exact path='/zakaznici' element={<PrivateRoute />}>
                        <Route exact path='/zakaznici' element={<ZakazniciList />} />
                    </Route>

                    <Route path='/zarizeni' element={<PrivateRoute />}>
                        <Route path='/zarizeni' element={<ZarizeniList />} />
                    </Route>

                    <Route path='/mapainstalaci' element={<PrivateRoute />}>
                        <Route path='/mapainstalaci' element={<MapaInstalaci />} />
                    </Route>
                    
                    <Route path="/login" element={<FormLogin navigate={navigate} />} />

                    <Route path="/zarizeni-nahled/:id" element={<ZarizeniNahled />} />

                    <Route path="/*" element={<DefaultRoute />} >
                    </Route>
                </Routes>
            </main>
            <div className="clear" />
            <Footer />
        </div>
    );
}

export default App;

import React from 'react';
//import { createRoot } from 'react-dom/client';        REACT v18
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
//import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import MessageBox from "./components/MessageBox/MessageBox";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
/*const root = createRoot(rootElement);         REACT v18

root.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>);*/

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <App />
    </BrowserRouter>, document.getElementById('root'));

const configuration = {
    onUpdate: (registration) => {
        if (registration && registration.waiting) {
            const result = MessageBox.open({
                title: "Dotaz",
                content: <p>Detekov�na nov� verze aplikace! Po potvrzen� okna prob�hne jej� instalace.</p>,
                buttons: [
                    { name: "Potvrdit", handler: () => "ano" },
                ]
            });

            if (result == "ano")
            {
                registration.waiting.postMessage({ type: 'SKIP_WAITING' });
                window.location.reload(true);
            }
        }
    }
};



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
/*serviceWorkerRegistration.register(
    //configuration
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

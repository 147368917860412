import React from 'react';
import AuthService from "../AuthService";
import { fetchWrapper } from "../FetchWrapper";
import styles from './Header.module.css'

import Global from "./Global";
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";


export class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

        this.logout = this.logout.bind(this);
        this.mapyUpdate = this.mapyUpdate.bind(this);
    }


    logout(e) {
        e.preventDefault();
        this.props.logout();
    }

    mapyUpdate(e) {
        e.preventDefault();
        LoadingSpinner.open();

        fetchWrapper.get(fetchWrapper.VratServisCerpadlo() + "/mapyupdate").then(data => {
            LoadingSpinner.close();
        }).catch(error => {
            LoadingSpinner.close();
            MessageBox.open({
                title: "Chyba",
                content: <p>Nepodařilo se aktualizovat mapy: {error.message}</p>,
                buttons: [
                    { name: "OK", handler: () => "OK" }
                ]
            });
        });
    }

    componentDidMount() {

    }

    

    render() {
        const user = AuthService.getCurrentUser();

        return (
            <div className="grid_12 header-repeat" >
                <div className="branding">
                    <div className="floatleft">
                        <img src={"./img/logo.png"} alt="Logo" className="header-logo" width="235" height="104" />
                        <div className="header-text">{this.props.title}</div>
                    </div>
                    {user && (
                        <div className="floatright">
                            <div className="floatright marginleft10">
                                <ul className="inline-ul floatleft">
                                    <li>{user.uziv_jmeno}</li>
                                    <li><a href="#" onClick={this.logout}>Odhlásit</a></li>
                                </ul>
                                <br />
                                <span className="small grey"></span>
                            </div>
                            <div className="floatright"><img src="./img/img-profile.jpg" alt="Profile Pic" width="20" height="20" /></div>
                        </div>
                    )}
                    <div className="clear"></div>
                </div>
            </div>
        );
    }
}
import AuthService from './AuthService';
import Global from './components/Global';

export const API_URL = window.SERVISAPI

export const fetchWrapper = {
    get,
    getBezCache,
    post,
    postBezAuth,
    put,
    del: del,
    fileLoad,
    fileLoadPost,

    VratZakazniky,
    VratZakaznika,
    VratZakaznikDetail,
    VratServisCerpadlo,
    VratServisCerpadloID,
    VratCerpadloDetail,
    VratCerpadlaRevize,
    VratCerpadlaRevizeID
};

function get(url) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(url)
    };


    return fetch(url, requestOptions).then(handleResponse);
}

function getBezCache(url) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0' },
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function post(url, body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        body: JSON.stringify(body)
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function postBezAuth(url, body) {
    const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json' },
        body: JSON.stringify(body)
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function del(url) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(url)
    };
    return fetch(url, requestOptions).then(handleResponse);
}

function fileLoad(url) {

    const headers = authHeader(url);
    headers["Content-Type"] = 'application/pdf';


    return fetch(url, {
        method: 'GET',
        headers: headers
    })
        .then((response) => response.blob())
        .then((file) => {
            /*
            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([file]),
            );            
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `FileName.pdf`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);*/

            return file;
        });
}

function fileLoadPost(url, body) {

    return fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader(url) },
        body: JSON.stringify(body)
    })
        .then((response) => response.blob())
        .then((file) => {
            return file;
        });
}

// helper functions
function authHeader(url) {
    // return auth header with jwt if user is logged in and request is to the api url   
    const userLogged = AuthService.getCurrentUser();
    const isLoggedIn = userLogged && userLogged.accessToken;

    /*const isApiUrl = url.startsWith(publicRuntimeConfig.apiUrl);*/
    if (isLoggedIn/* && isApiUrl*/) {
        return { Authorization: `Bearer ${userLogged.accessToken}`, "KlientApiVerze": window.KLIENT_API_VERZE };
    } else {
        return {};
    }
}

function handleResponse(response) {

    return response.text().then(text => {
        if (response.status == 400) {

            const error = response.statusText + ": " + text;
            return Promise.reject(error);
        }

        const data = text && JSON.parse(text);
        const user = AuthService.getCurrentUser();

        if (!response.ok) {
            if ([401, 403].includes(response.status) && user) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                AuthService.logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function VratZakazniky() {
    return API_URL + "ServisZakaznik";
}

function VratZakaznika(seza_id) {
    return API_URL + "ServisZakaznik/" + seza_id;
}

function VratZakaznikDetail(seza_id) {
    return API_URL + "ServisZakaznik/detail/" + seza_id;
}

function VratServisCerpadlo() {
    return API_URL + "ServisCerpadla";
}

function VratServisCerpadloID(cerp_id) {
    return API_URL + "ServisCerpadla/" + cerp_id;
}

function VratCerpadloDetail(cerp_id) {
    return API_URL + "ServisCerpadla/detail/" + cerp_id;
}

function VratCerpadlaRevize() {
    return API_URL + "ServisCerpadlaRevize";
}

function VratCerpadlaRevizeID(cere_id) {
    return API_URL + "ServisCerpadlaRevize/" + cere_id;
}


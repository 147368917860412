import React from 'react';
import { NavLink } from 'react-router-dom'
import './Navbar.css'

import AuthService from '../../AuthService';
import CacheData from '../../CacheData';

import { fetchWrapper } from "../../FetchWrapper";
import MessageBox from "../MessageBox/MessageBox";
import LoadingSpinner from "../Loader/LoadingSpinner";

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNavbar: false,
            vybranyZakaznik: null
        };

        this.handleShowNavbar = this.handleShowNavbar.bind(this);
        this.handleStorage = this.handleStorage.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.aktivniZakaznikNaHlavniStranku = this.aktivniZakaznikNaHlavniStranku.bind(this);
    }

    /*Router.events.on('routeChangeComplete', (url) => {
  if (window.location.pathname == "/uzivatele")  
  {
    this.setState( {classesUsers: "ic-users current", classesVentilatory: "ic-fans"});
  }
  else if (window.location.pathname == "/zarizeni") 
  {
    this.setState( {classesUsers: "ic-users", classesVentilatory: "ic-fans current"});
  }
});*/

    componentDidMount() {
        this.handleStorage();

        window.addEventListener('storageUpdate', this.handleStorage);
    }

    componentWillUnmount() {
        window.removeEventListener('storageUpdate', this.handleStorage)
    }

    handleStorage() {
        let aktualniZakaznik = CacheData.GetData(AuthService.getCurrentUser(), "servisZakaznik");
        this.setState({ vybranyZakaznik: aktualniZakaznik });
    }

    handleShowNavbar() {
        this.setState({ showNavbar: !this.state.showNavbar })
    }

    reloadData(event) {
        event.preventDefault();

        if (this.state.vybranyServisniVyjezd)
        {
            LoadingSpinner.open();
            fetchWrapper.get(fetchWrapper.VratServisniVyjezd(this.state.vybranyServisniVyjezd.sevy_id))
                .then(dataVyjezd => {
                    LoadingSpinner.close();

                    this.setState({ vybranyServisniVyjezd: dataVyjezd });
                    CacheData.SetData(AuthService.getCurrentUser(), "servisZakaznik", dataVyjezd);

                    window.dispatchEvent(new Event('reloadData'));
                })
                .catch(error => {
                    LoadingSpinner.close();
                   

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se načíst data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
            
        }
        else window.dispatchEvent(new Event('reloadData'));

    }

    aktivniZakaznikNaHlavniStranku() {
        if (this.state.showNavbar) this.handleShowNavbar();

        window.dispatchEvent(new Event('aktivniZakaznikNaHlavniStranku'));
    }

    render() {
        const uziv = AuthService.getCurrentUser();

        if (!uziv) return <></>;

        return (
            <nav className="navbar">
                <div className="container">
                    <div className="logo">

                    </div>
                    <div className="menu-icon" onClick={this.handleShowNavbar}>
                        <img src="./img/toggleMenu.png"></img>
                    </div>
                    <div className={`nav-elements  ${this.state.showNavbar && 'active'}`}>
                        <ul className="nav">
                            {uziv && uziv.uziv_role.includes("A") &&
                                <>
                                    <li>
                                        <NavLink to="/zakaznici" onClick={this.state.showNavbar && this.handleShowNavbar}><img src="./img/users.png" /> <div className="odkaz_text">Zákazníci</div></NavLink>
                                    </li>
                            


                                    <li>
                                        <NavLink to="/mapainstalaci" onClick={this.state.showNavbar && this.handleShowNavbar}><img src="./img/map.png" /> <div className="odkaz_text">Mapa instalací</div></NavLink>
                                    </li>
                                </>
                            }
                        </ul>
                    </div>
{/*
                    <div className="menu_refresh">
                        <ul className="nav">
                            <li>
                                <a onClick={this.reloadData}><img src="./img/refresh.png" /></a>
                            </li>
                        </ul>
                    </div>
*/}
                </div>
            </nav>
        )
    }
}


export default Navbar
import React from 'react';
import { useState, useEffect } from 'react';
import { fetchWrapper } from "../FetchWrapper";
import { ZarizeniDetail } from './ZarizeniDetail';
import { useNavigate } from "react-router-dom";

import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";

export function ZarizeniNahled() {   
    const [vybranyDetailZarizeni, setvybranyDetailZarizeni] = useState(undefined);

    const navigate = useNavigate();

    useEffect(() => {
        const zariID = window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1);

        LoadingSpinner.open();

        fetchWrapper.get(fetchWrapper.VratZarizeni(zariID))
            .then(data => {
                LoadingSpinner.close();

                setvybranyDetailZarizeni(data);
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });


    }, []);

    const zavritDetailZarizeni = (e) => {
        e.preventDefault();
        navigate("/login");
    }


    return (<div className="grid_12" style={{position: "relative"} }>
        <button style={{ position: "absolute", top: -50, right: 15, height: 50, width: 200 }} onClick={zavritDetailZarizeni}>Zavřít detail zařízení</button>
        {vybranyDetailZarizeni && <ZarizeniDetail nadpis="Detail zařízení" zarizeniData={vybranyDetailZarizeni} zobrazitTlacitkoZpet={false} bAkce={false} zobrazitMereni={false} />}
        {!vybranyDetailZarizeni && <div style={{ height: 200, textAlign: "center" }}>Zařízení nebylo nalezeno.</div>}

            </div>
        );
  } 
import React from 'react';
import { ReactTabulator } from 'react-tabulator';
import { reactFormatter } from 'react-tabulator/lib/Utils.js?version=4.0';
import styles from './ZakaznikDetail.module.css'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { cs } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { MuiTheme } from "./MuiTheme";

import { ZarizeniDetail } from "./ZarizeniDetail";
import { EditorHodnotyForm } from "./EditorHodnotyForm"
import { Stack, Select, MenuItem, FormControl, TextField } from '@mui/material';

import AuthService from "../AuthService";
import CacheData from "../CacheData";
import { fetchWrapper } from "../FetchWrapper";

import { ObjektDetailHodnotaRadek } from "./ObjektDetailHodnotaRadek";

import { SMapyV2 } from "./SMapy/SMapyV2"

import { Global } from "./Global";

import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";

import { ZakaznikDetailZarizeni } from "./ZakaznikDetailZarizeni";

const MAP_ICONS = window.MAP_ICONS

export class MapaInstalaci extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            zobrazenyPanel: "MAPA",
            detailZarizeniVMape: null,
            mistoAdresa: { lat: 49.5953394, lng: 14.2338297 },
            geojson: null,
            listCerpadla: [],

            filtrZobrazitZrusene: false,
            filtr0DniDalsiRevize: true,
            filtr1MesicDalsiRevize: true,
            filtr2MesicDalsiRevize: true,
            filtrOstatniDalsiRevize: true,

            bProbehlUpdateFiltru: false,
            vybraneZarizeniDetail: null,

            pocetInstalaciZrusene: 0,
            pocetInstalaci0Dni: 0,
            pocetInstalaci1Mesic: 0,
            pocetInstalaci2Mesic: 0,
            pocetInstalaciOstatni: 0
        };

        this.zavritDetailZarizeniEvent = this.zavritDetailZarizeniEvent.bind(this);
        this.vyberZarizeniVMape = this.vyberZarizeniVMape.bind(this);
        this.zavritDetailZarizeniVMape = this.zavritDetailZarizeniVMape.bind(this);
        this.refreshSeznamZarizeniProMapu = this.refreshSeznamZarizeniProMapu.bind(this);
        this.formSubmit = this.formSubmit.bind(this);
        this.otevritCelyDetail = this.otevritCelyDetail.bind(this);
    }

    componentDidMount() {
        fetchWrapper.get(fetchWrapper.VratServisCerpadlo())
            .then(data => {
                this.setState({ listCerpadla: data });
                this.refreshSeznamZarizeniProMapu();

                LoadingSpinner.close();
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst data: {error.message}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }

    componentWillUnmount() {

    }

    componentDidUpdate(prevProps) {
        if (this.state.bProbehlUpdateFiltru) this.refreshSeznamZarizeniProMapu();
    }

    refreshSeznamZarizeniProMapu() {
        var zarizeniBodyMapa = [];

        const datumAktualni = Date.now();

        var pocetInstZrusene = 0;
        var pocetInst0Dni = 0;
        var pocetInst1Mesic = 0;
        var pocetInst2Mesic = 0;
        var pocetInstOstatni = 0;


        this.state.listCerpadla.forEach((dr) => {
            if (dr.cerp_mapa_lng != '' && dr.cerp_mapa_lat != '') {
                var img = 'pin_black.png';
                if (dr.cerp_pravidelnarevize == 0 || dr.cerp_zruseno) img = 'circle_grey.png';

                if (!dr.cerp_pravidelnarevize || dr.cerp_zruseno) {
                    pocetInstZrusene++;
                    if (!this.state.filtrZobrazitZrusene) return;
                }


                if (dr.cerp_pravidelnarevize && !dr.cerp_zruseno) {
                    const datumPosledniRevize = Global.dateToObj(dr.cerp_datumposlednirevize);
                    const dniOdPosledniRevize = Math.round((datumAktualni - datumPosledniRevize) / (1000 * 60 * 60 * 24))

                    if (dniOdPosledniRevize > 365) {
                        pocetInst0Dni++;
                        img = 'pin_purple.png';
                        if (!this.state.filtr0DniDalsiRevize) return;
                    }
                    else if (dniOdPosledniRevize <= 365 && dniOdPosledniRevize > 335) {
                        pocetInst1Mesic++;
                        img = 'pin_red.png';
                        if (!this.state.filtr1MesicDalsiRevize) return;
                    }
                    else if (dniOdPosledniRevize <= 335 && dniOdPosledniRevize > 305) {
                        pocetInst2Mesic++;
                        img = 'pin_yellow.png';
                        if (!this.state.filtr2MesicDalsiRevize) return;
                    }
                    else {
                        pocetInstOstatni++;
                        img = 'pin_black.png';
                        if (!this.state.filtrOstatniDalsiRevize) return;
                    }
                }

                zarizeniBodyMapa.push({
                    'type': 'Feature',
                    'properties': {
                        'message': '',
                        'data': dr,
                        'iconSize': [32, 32],
                        'url': 'url(' + MAP_ICONS + img + ')'
                    },
                    'geometry': {
                        'type': 'Point',
                        'coordinates': [parseFloat(dr.cerp_mapa_lng), parseFloat(dr.cerp_mapa_lat)]
                    }
                });
            }
        });

        this.setState({
            geojson: zarizeniBodyMapa, bProbehlUpdateFiltru: false, pocetInstalaciZrusene: pocetInstZrusene,
            pocetInstalaci0Dni: pocetInst0Dni, pocetInstalaci1Mesic: pocetInst1Mesic, pocetInstalaci2Mesic: pocetInst2Mesic,
            pocetInstalaciOstatni: pocetInstOstatni
        });
    }

    zavritDetailZarizeniEvent() {
        this.state.detailZarizeniVMape.cerp_znacka = this.state.vybraneZarizeniDetail.cerpadlo.cerp_znacka;
        this.state.detailZarizeniVMape.cerp_typ = this.state.vybraneZarizeniDetail.cerpadlo.cerp_typ;
        this.state.detailZarizeniVMape.cerp_chladivo = this.state.vybraneZarizeniDetail.cerpadlo.cerp_chladivo;
        this.state.detailZarizeniVMape.cerp_pravidelnarevize = this.state.vybraneZarizeniDetail.cerpadlo.cerp_pravidelnarevize;

        this.state.detailZarizeniVMape.cerp_ulice = this.state.vybraneZarizeniDetail.cerpadlo.cerp_ulice;
        this.state.detailZarizeniVMape.cerp_mesto = this.state.vybraneZarizeniDetail.cerpadlo.cerp_mesto;
        this.state.detailZarizeniVMape.cerp_psc = this.state.vybraneZarizeniDetail.cerpadlo.cerp_psc;
        this.state.detailZarizeniVMape.cerp_datumposlednirevize = this.state.vybraneZarizeniDetail.cerpadlo.cerp_datumposlednirevize;


        this.setState({ zobrazenyPanel: "MAPA", vybraneZarizeniDetail: null })
    }


    vyberZarizeniVMape(drZar) {
        if (drZar) {
            
            this.setState({ detailZarizeniVMape: drZar });
        }
    }

    zavritDetailZarizeniVMape() {
        this.setState({ detailZarizeniVMape: null });
    }

    formSubmit(event) {
        event.preventDefault();
    }

    otevritCelyDetail() {
           LoadingSpinner.open();

           fetchWrapper.get(fetchWrapper.VratCerpadloDetail(this.state.detailZarizeniVMape.cerp_id))
            .then(data => {
                LoadingSpinner.close();
                this.setState({vybraneZarizeniDetail : data, zobrazenyPanel: "DETAIL" });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst data: {error.message}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });

        
    }


    render() {
        const user = AuthService.getCurrentUser();


        return (
            <>
                <div>
                    <div style={{ display: this.state.zobrazenyPanel == "MAPA" ? "block" : "none" }}>

                        <div className="grid_12">
                            <h1>Mapa instalací </h1>
                            <br />
                            <form onSubmit={this.formSubmit}>
                                <div className=" ">
                                    <div className="form_udaj_container form_udaj_container_inline">
                                        <EditorHodnotyForm popis={"Bez pravidelných revizí / zrušené (" + this.state.pocetInstalaciZrusene + ")"} hodnota={this.state.filtrZobrazitZrusene} widthLeft={280}
                                            stylesRight={{ maxWidth: 50 }} typ="CHECKBOX" img="./img/mapa/circle_grey.png"
                                            onChange={e => { this.setState({ filtrZobrazitZrusene: e.target.checked ? true : false, bProbehlUpdateFiltru: true }); }} />

                                    </div>
                                    <div className="form_udaj_container form_udaj_container_inline">
                                        <EditorHodnotyForm popis={"Bez platné revize (" + this.state.pocetInstalaci0Dni + ")"} hodnota={this.state.filtr0DniDalsiRevize} widthLeft={280}
                                            stylesRight={{ maxWidth: 50 }} typ="CHECKBOX" img="./img/mapa/pin_purple.png"
                                            onChange={e => { this.setState({ filtr0DniDalsiRevize: e.target.checked ? true : false, bProbehlUpdateFiltru: true }); }} />
                                    </div>
                                    <div className="form_udaj_container form_udaj_container_inline">
                                        <EditorHodnotyForm popis={"Měsíc do další revize (" + this.state.pocetInstalaci1Mesic + ")"} hodnota={this.state.filtr1MesicDalsiRevize} widthLeft={280}
                                            stylesRight={{ maxWidth: 50 }} typ="CHECKBOX" img="./img/mapa/pin_red.png"
                                            onChange={e => { this.setState({ filtr1MesicDalsiRevize: e.target.checked ? true : false, bProbehlUpdateFiltru: true }); }} />
                                    </div>
                                    <div className="form_udaj_container form_udaj_container_inline">
                                        <EditorHodnotyForm popis={"2 měsíce do další revize (" + this.state.pocetInstalaci2Mesic + ")"} hodnota={this.state.filtr2MesicDalsiRevize} widthLeft={280}
                                            stylesRight={{ maxWidth: 50 }} typ="CHECKBOX" img="./img/mapa/pin_yellow.png"
                                            onChange={e => { this.setState({ filtr2MesicDalsiRevize: e.target.checked ? true : false, bProbehlUpdateFiltru: true }); }} />
                                    </div>
                                    <div className="form_udaj_container form_udaj_container_inline">
                                        <EditorHodnotyForm popis={"Ostatní (" + this.state.pocetInstalaciOstatni + ")"} hodnota={this.state.filtrOstatniDalsiRevize} widthLeft={280}
                                            stylesRight={{ maxWidth: 50 }} typ="CHECKBOX" img="./img/mapa/pin_black.png"
                                            onChange={e => { this.setState({ filtrOstatniDalsiRevize: e.target.checked ? true : false, bProbehlUpdateFiltru: true }); }} />
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="grid_12" style={{ minHeight: 800, maxWidth: 1400, position: "relative" }}>
                            {this.state.detailZarizeniVMape &&
                                <div style={{ position: "absolute", top: 50, left: 50, width: 450, zIndex: 10000 }}>
                                    <div className="grid_12">
                                        <div className="box round" style={{ minHeight: 350, position: "relative" }}>
                                            <h2>Základní údaje <button style={{ float: "right" }} onClick={this.zavritDetailZarizeniVMape}>Zavřít detail</button></h2>
                                            <div className="grid_12">
                                                <ObjektDetailHodnotaRadek popis="Značka:" widthLeft={130} hodnota={this.state.detailZarizeniVMape.cerp_znacka} />
                                                <ObjektDetailHodnotaRadek popis="Model:" widthLeft={130} hodnota={this.state.detailZarizeniVMape.cerp_model} />
                                                <ObjektDetailHodnotaRadek popis="Typ:" widthLeft={130} hodnota={Global.EnumCerpadloTyp(this.state.detailZarizeniVMape.cerp_typ)} />
                                                <ObjektDetailHodnotaRadek popis="Chladivo:" widthLeft={130} hodnota={this.state.detailZarizeniVMape.cerp_chladivo} />
                                            </div>
                                            <div className="clear" />
                                            <h3>Místo instalace</h3>
                                            <div className="grid_12">
                                                <ObjektDetailHodnotaRadek popis="Zákazník:" widthLeft={130} hodnota={this.state.detailZarizeniVMape.seza_jmeno} />
                                                <ObjektDetailHodnotaRadek popis="Kontakt:" widthLeft={130} hodnota={this.state.detailZarizeniVMape.seza_telefon + " , " + this.state.detailZarizeniVMape.seza_email} />
                                                <ObjektDetailHodnotaRadek popis="Ulice:" widthLeft={130} hodnota={this.state.detailZarizeniVMape.cerp_ulice} />
                                                <ObjektDetailHodnotaRadek popis="Město:" widthLeft={130} hodnota={this.state.detailZarizeniVMape.cerp_mesto} />
                                                <ObjektDetailHodnotaRadek popis="PSČ:" widthLeft={130} hodnota={this.state.detailZarizeniVMape.cerp_psc} />
                                                <div className="odsazeni_bottom10 odsazeni_top10"> </div>
                                                <ObjektDetailHodnotaRadek popis="Pravidelná revize:" widthLeft={130} hodnota={(this.state.detailZarizeniVMape.cerp_pravidelnarevize ? "ANO" : "NE") + "     (poslední rev.: " + Global.dateDBFormatToDateStrBezCasu(this.state.detailZarizeniVMape.cerp_datumposlednirevize) + ")"} />
                                            </div>

                                            <div>
                                                {this.state.detailZarizeniVMape.cerp_zruseno && <div style={{ position: "absolute", bottom: 10, left: 10, fontSize: 20,color: "red", fontWeight: "bold" }}>Zrušeno {Global.dateDBFormatToDateStrBezCasu(this.state.detailZarizeniVMape.cerp_zrusenodatum)}</div>}
                                                <button className="form_button" style={{ position: "absolute", bottom: 10, right: 10 }} onClick={this.otevritCelyDetail}> Přejít na zařízení</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <SMapyV2 height={650} mistoAdresa={this.state.mistoAdresa} geojson={this.state.geojson} vyberZarizeniVMape={this.vyberZarizeniVMape} zoom={8} />
                        </div>

                    </div>

                    {this.state.zobrazenyPanel == "DETAIL" &&
                        <ZakaznikDetailZarizeni bAkce={true} bSkrytAkciVymaz={true} vybranyZakaznikDetail={this.props.vybranyZakaznikDetail} vybraneZarizeniDetail={this.state.vybraneZarizeniDetail} zavritDetail={this.zavritDetailZarizeniEvent} />
                    }
                </div>
            </>
        );
    }
}




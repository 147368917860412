import { format } from 'date-fns'

const DateEmpty = "1900-01-01 00:00:00";
const GuidEmpty = "00000000-0000-0000-0000-000000000000";

function JeGuidValid(hodnota) {
    const bOK = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(hodnota);

    return bOK && hodnota != GuidEmpty;
}

function dateToObj(dateStr) {
    return new Date(dateStr);
}

function dateToStr(date) {
    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds());
}

function toISOString(date) {

    var tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
            return (num < 10 ? '0' : '') + num;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds())
        /*+ "Z"*/;

        /* místo Z na konci vloži UTC značku posun (+001 například)
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60)*/;
}

function textAreaOpravaPredUlozeni(text) {
    return text.replaceAll("\r\n", "\n").replaceAll("\n", "\r\n");
}

function toDateTimeStr(date) {

    if (date.getFullYear() <= 1753) return "";

    return format(date, "dd.MM.yyyy HH:mm:ss");
    
}

function toDateTimeStrBezSek(date) {

    if (date.getFullYear() <= 1753) return "";

    return format(date, "dd.MM.yyyy HH:mm");

}

function toDateStr(date) {

    if (date.getFullYear() <= 1753) return "";

    return format(date, "dd.MM.yyyy");

}

function dateDBFormatToDateStr(date) {
    if (date.replaceAll("T", " ") == DateEmpty) return "";

    return date.replaceAll("T", " ");
}

function dateDBFormatToDateStrBezCasu(date) {
    return toDateStr(dateToObj(date))
}

function EnumCerpadloTyp(kod) {
    if (kod == 0) return "Monoblok";
    else if (kod == 1) return "Split";
    else return "Jiný";
}

function EnumCerpadloTypCiselnik() {
    return [
        { kod: 0, popis: "Monoblok" },
        { kod: 1, popis: "Split" },
        { kod: -1, popis: "Jiný" }
    ];
}


export const Global = {
    toISOString,
    toDateTimeStr,
    toDateStr,
    EnumCerpadloTyp,
    EnumCerpadloTypCiselnik,

    toDateTimeStrBezSek,
    textAreaOpravaPredUlozeni,
    JeGuidValid,
    dateToStr,
    dateToObj,
    dateDBFormatToDateStr,
    dateDBFormatToDateStrBezCasu,

    GuidEmpty,
    DateEmpty
};

export default Global;




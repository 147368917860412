import React from 'react';
import { ReactTabulator } from 'react-tabulator';
import { reactFormatter } from 'react-tabulator/lib/Utils.js?version=4.0';
import styles from './ZakaznikDetailZarizeni.module.css'

import { ObjektDetailHodnotaRadek } from "./ObjektDetailHodnotaRadek";

import { CameraFeed } from './CameraFeed';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { cs } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { MuiTheme } from "./MuiTheme";

import { fetchWrapper, API_URL } from "../FetchWrapper";
import AuthService from "../AuthService"
import CacheData from "../CacheData"


import { Global } from './Global';
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";

import { format } from 'date-fns'
import { CerpadloForm } from './CerpadloForm';

import { SMapyV2 } from "./SMapy/SMapyV2"
import { ServisRevizeForm } from './ServisRevizeForm';


const tabServisZaznamy = [
    {
        field: 'cere_typ', title: 'Typ', width: 220, headerSort: true, formatter: function (cell, formatterParams, onRendered) {
            if (cell.getValue() == "PP") return "Předávací protokol";
            else if (cell.getValue() == "SK") return "Servisní kontrola";
            else if (cell.getValue() == "RE") return "Reklamace";
            else return "Jiné";
        }
    },
    {
        field: 'cere_datum', title: 'Datum vzniku', width: 150, headerSort: true, resizable: true, formatter: function (cell, formatterParams, onRendered) {

            return Global.toDateTimeStr(new Date(cell.getValue()));
        }
    },
    {
        field: 'cere_soubor_nazev', title: 'Odkaz na soubor', headerSort: false, formatter: "image", formatter: function (cell, formatterParams) {
            var value = cell.getValue();

            if ("" + value != "") return "<a href=\"" + API_URL + "serviscerpadlarevize/soubor/" + cell.getData().cere_id + "\" onclick=\"\" target=\"_blank\" style='background-color: #0C2249; color: yellow; padding: 5px; font-weight:bold;'>" + value + "</a>";

            return "";
        }
    }
];


export class ZakaznikDetailZarizeni extends React.Component {
    constructor(props) {
        super(props);

        console.log(this.props.vybraneZarizeniDetail);
        this.state = {
            zobrazenyPanel: "DETAIL", detailSekce: "VLASTNOSTI", revizeData: props.vybraneZarizeniDetail.revize, vybranyZaznamRevize: null,
            mistoAdresa: { lat: this.props.vybraneZarizeniDetail.cerpadlo.cerp_mapa_lat, lng: this.props.vybraneZarizeniDetail.cerpadlo.cerp_mapa_lng }
        };

        this.zavritDialogZaznam = this.zavritDialogZaznam.bind(this);

        this.noveFoto = this.noveFoto.bind(this);

        this.zarizeniUpravitZaznam = this.zarizeniUpravitZaznam.bind(this);
        this.zarizeniSmazatZaznam = this.zarizeniSmazatZaznam.bind(this);
        this.novyZaznamServisu = this.novyZaznamServisu.bind(this);
        this.revizeVyber = this.revizeVyber.bind(this);
    }


    zavritDialogZaznam(bZmena) {
        if (bZmena) {
            LoadingSpinner.open();

            fetchWrapper.get(fetchWrapper.VratCerpadloDetail(this.props.vybraneZarizeniDetail.cerpadlo.cerp_id))
                .then(data => {
                    LoadingSpinner.close();

                    this.props.vybraneZarizeniDetail.revize = data.revize;
                    this.props.vybraneZarizeniDetail.cerp_datumposlednirevize = data.cerpadlo.cerp_datumposlednirevize;
                    this.setState({ zobrazenyPanel: "DETAIL", revizeData: this.props.vybraneZarizeniDetail.revize, 
                                    mistoAdresa: { lat: this.props.vybraneZarizeniDetail.cerpadlo.cerp_mapa_lat, lng: this.props.vybraneZarizeniDetail.cerpadlo.cerp_mapa_lng }
                    });
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se načíst data: {error}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });


        }
        else this.setState({ zobrazenyPanel: "DETAIL" });
    }

    componentDidMount() {

    }

    revizeVyber = (e, row) => {
        if (e.target.matches("a") || e.target.attributes["tabulator-field"].value == "cere_soubor_nazev") {
            return;
        }

        this.setState({ zobrazenyPanel: "REVIZEFORM", vybranyZaznamRevize: row.getData() });
        //window.scrollTo(0, 0);
    }

    noveFoto(file, popisZadany) {
        /*const uziv = AuthService.getCurrentUser();
        console.log(uziv);

        const sefo_datum = new Date();
        const sefo_nazev_zdroj = this.state.vybraneSeveZarizeni.seve_zarizeni.vent_vyrobni_cislo + "_" + format(sefo_datum, "yyyy_MM_dd") + "_" + uziv.uziv_inicialy + ".png";

        const dataFoto = {
            sefo_datum: sefo_datum,
            sefo_nazev_zdroj: sefo_nazev_zdroj,
            sefo_popis: popisZadany,
            sevy_id: this.props.sevyData.sevy_id,
            vent_id: this.state.vybraneSeveZarizeni.seve_zarizeni.vent_id
        }


        LoadingSpinner.open();
        fetchWrapper.post(fetchWrapper.NovyServisFoto(), dataFoto)
            .then(data => {
                LoadingSpinner.close();

                const filename = data.sefo_nazev_zdroj;
                const aTag = document.createElement('a');

                aTag.href = file;
                aTag.download = data.sefo_nazev_zdroj;
                aTag.click();

                MessageBox.open({
                    title: "Zpráva",
                    content: <p>Foto bylo úspěšně zpracováno.</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se zpracovat data:<br /> {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });*/
    }

    novyZaznamServisu = async (e, cell) => {
        this.setState({ zobrazenyPanel: "REVIZEFORM", vybranyZaznamRevize: null });
    }


    zarizeniUpravitZaznam = async (e, cell) => {
        this.setState({ zobrazenyPanel: "ZARIZENIFORM" });
    }

    zarizeniSmazatZaznam = async (e, cell) => {

        const result = await MessageBox.open({
            title: "Dotaz",
            content: <p>Opravdu chcete vybrané zařízení smazat?</p>,
            buttons: [
                { name: "Ano", handler: () => "ano" },
                { name: "Ne", handler: () => "ne" }
            ]
        });

        if (result == "ano") {
            LoadingSpinner.open();
            fetchWrapper.del(fetchWrapper.VratServisCerpadloID(this.props.vybraneZarizeniDetail.cerpadlo.cerp_id))
                .then(data => {
                    const index = this.props.vybranyZakaznikDetail.cerpadla.indexOf(this.props.vybraneZarizeniDetail.cerpadlo);
                    if (index > -1) {
                        this.props.vybranyZakaznikDetail.cerpadla.splice(index, 1);
                    }

                    LoadingSpinner.close();

                    this.props.zavritDetail();
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se zpracovat data:<br /> {error.message}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
    }

    render() {
        const adresaValidni = this.state.mistoAdresa.lat > 0 && this.state.mistoAdresa.lng  > 0;



        const bAkceVymaz = this.props.bSkrytAkciVymaz ? false : true 

        return (
            <div>
                {this.state.zobrazenyPanel === "ZARIZENIFORM" &&
                    <div className="odsazeni_bottom10">
                        <div className="grid_12">
                            <CerpadloForm vybranyZakaznikDetail={this.props.vybranyZakaznikDetail} drZarizeni={this.props.vybraneZarizeniDetail.cerpadlo} zavritDialog={this.zavritDialogZaznam} />
                        </div>
                        <div className="clear" />
                    </div>
                }

                {this.state.zobrazenyPanel === "REVIZEFORM" &&
                    <div className="odsazeni_bottom10">
                        <div className="grid_12">
                            <ServisRevizeForm vybraneZarizeniDetail={this.props.vybraneZarizeniDetail} drRevize={this.state.vybranyZaznamRevize} zavritDialog={this.zavritDialogZaznam} />
                        </div>
                        <div className="clear" />
                    </div>
                }

                <div className="odsazeni_bottom10" style={{ minHeight: 500, display: this.state.zobrazenyPanel === "DETAIL" ? 'block' : 'none' }}>
                    <div className="grid_12" onContextMenu={(e) => e.preventDefault()}>
                        <h1>Zařízení&nbsp; &nbsp;
                            <button className="form_button" style={{ marginRight: 30 }} onClick={this.props.zavritDetail}>Zpět</button>
                            <button className="form_button" style={{ marginRight: 30 }} onClick={this.zarizeniUpravitZaznam}>Upravit</button>
                            {bAkceVymaz && <button className="form_button" onClick={this.zarizeniSmazatZaznam}>Smazat</button> }
                        </h1>



                        <ThemeProvider theme={MuiTheme}>
                            <LocalizationProvider adapterLocale={cs} dateAdapter={AdapterDateFns}>
                                <div className="odsazeni_bottom10">
                                    <div className="grid_5" >
                                        <div className="box round" style={{ minHeight: 420 }}>
                                            <h2>Základní údaje</h2>
                                            <div className="grid_12">
                                                <ObjektDetailHodnotaRadek popis="Značka:" widthLeft={130} hodnota={this.props.vybraneZarizeniDetail.cerpadlo.cerp_znacka} />
                                                <ObjektDetailHodnotaRadek popis="Model:" widthLeft={130} hodnota={this.props.vybraneZarizeniDetail.cerpadlo.cerp_model} />
                                                <ObjektDetailHodnotaRadek popis="Typ:" widthLeft={130} hodnota={Global.EnumCerpadloTyp(this.props.vybraneZarizeniDetail.cerpadlo.cerp_typ)} />
                                                {this.props.vybraneZarizeniDetail.cerpadlo.cerp_typ == 1 &&
                                                    <>
                                                        <ObjektDetailHodnotaRadek popis="S/N (venkovní):" widthLeft={130} hodnota={this.props.vybraneZarizeniDetail.cerpadlo.cerp_sn_venkovni} />
                                                        <ObjektDetailHodnotaRadek popis="S/N (vnitřní):" widthLeft={130} hodnota={this.props.vybraneZarizeniDetail.cerpadlo.cerp_sn_vnitrni} />
                                                    </>
                                                }
                                                {this.props.vybraneZarizeniDetail.cerpadlo.cerp_typ == 0 &&
                                                    <ObjektDetailHodnotaRadek popis="S/N:" widthLeft={130} hodnota={this.props.vybraneZarizeniDetail.cerpadlo.cerp_sn_venkovni} />
                                                }
                                                <ObjektDetailHodnotaRadek popis="Chladivo:" widthLeft={130} hodnota={this.props.vybraneZarizeniDetail.cerpadlo.cerp_chladivo} />
                                            </div>
                                            <div className="clear" />
                                            <h3>Místo instalace</h3>
                                            <div className="grid_12">
                                                <ObjektDetailHodnotaRadek popis="Ulice:" widthLeft={130} hodnota={this.props.vybraneZarizeniDetail.cerpadlo.cerp_ulice} />
                                                <ObjektDetailHodnotaRadek popis="Město:" widthLeft={130} hodnota={this.props.vybraneZarizeniDetail.cerpadlo.cerp_mesto} />
                                                <ObjektDetailHodnotaRadek popis="PSČ:" widthLeft={130} hodnota={this.props.vybraneZarizeniDetail.cerpadlo.cerp_psc} />
                                                <div className="odsazeni_bottom10 odsazeni_top10"> </div>
                                                <ObjektDetailHodnotaRadek popis="Datum instalace:" widthLeft={130} hodnota={Global.dateDBFormatToDateStrBezCasu(this.props.vybraneZarizeniDetail.cerpadlo.cerp_datuminstalace)} />
                                                <ObjektDetailHodnotaRadek popis="Instaloval:" widthLeft={130} hodnota={this.props.vybraneZarizeniDetail.cerpadlo.cerp_instaloval} />
                                                <ObjektDetailHodnotaRadek popis="Spustil:" widthLeft={130} hodnota={this.props.vybraneZarizeniDetail.cerpadlo.cerp_spustil} />
                                                <ObjektDetailHodnotaRadek popis="Pravidelná revize:" widthLeft={130} hodnota={this.props.vybraneZarizeniDetail.cerpadlo.cerp_pravidelnarevize ? "ANO" : "NE"} />
                                                <ObjektDetailHodnotaRadek popis="Datum posl.revize:" widthLeft={130} hodnota={Global.dateDBFormatToDateStrBezCasu(this.props.vybraneZarizeniDetail.cerpadlo.cerp_datumposlednirevize)} />
                                            </div>
                                            <div className="clear" />

                                            {this.props.vybraneZarizeniDetail.cerpadlo.cerp_zruseno == 1 &&
                                                <div style={{ color: "red", fontSize: 20, fontWeight: "bold" }}><br />Zrušeno {Global.dateDBFormatToDateStrBezCasu(this.props.vybraneZarizeniDetail.cerpadlo.cerp_zrusenodatum)}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="grid_7">
                                        <div className="box round" style={{ height: 420 }}>
                                            <h2>Adresa instalace</h2>
                                            {adresaValidni && <SMapyV2 mistoAdresa={this.state.mistoAdresa} /> }
                                        </div>
                                    </div>

                                    <div className="clear" />

                                    <div className="grid_12" >
                                        <h2>Poznámka</h2>
                                        <ObjektDetailHodnotaRadek popis="" typ="TEXTAREA" hodnota={this.props.vybraneZarizeniDetail.cerpadlo.cerp_poznamka} widthLeft={0} />
                                    </div>

                                    <div className="grid_12" onContextMenu={(e) => e.preventDefault()}>
                                        <h2>Záznamy servisu <button className="form_button" style={{ marginLeft: 20 }} onClick={this.novyZaznamServisu}><img src="./img/new.png"></img></button></h2>
                                        <ReactTabulator
                                            data={this.state.revizeData}
                                            columns={tabServisZaznamy}
                                            layout={'fitDataStretch'}
                                            height={'200px'}
                                            events={{ rowClick: this.revizeVyber }}
                                            initialSort={[{ column: "cere_datum", dir: "desc" }]}
                                        />
                                    </div>

                                    { /* }
                                    <div className="grid_12" onContextMenu={(e) => e.preventDefault()}>
                                        <<h2>Fotodokumentace <button className="form_button" style={{ marginLeft: 20 }} onClick={this.noveFoto}><img src="./img/new.png"></img></button></h2>
                                    </div>*/}

                                    <div className="clear" />
                                </div>
                            </LocalizationProvider>
                        </ThemeProvider>
                    </div>
                    <div className="clear" />


                </div>


                {this.state.zobrazenyPanel === "FOTO" && <CameraFeed noveFoto={this.noveFoto} />}

            </div >);
    }
}

import React from 'react';
import { Combobox } from "./Combobox";
import { fetchWrapper } from "../FetchWrapper";
import AuthService from "../AuthService";

import styles from './ServisRevizeForm.module.css'

import { EditorHodnotyForm } from "./EditorHodnotyForm";

import { FormControlLabel, Checkbox } from '@mui/material';

import Global from "./Global";
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from './Loader/LoadingSpinner';
import Modal from './MessageBox/Modal';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { cs } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { MuiTheme } from "./MuiTheme";


export class ServisRevizeForm extends React.Component {
    constructor(props) {
        super(props);

        if (props.drRevize)
            this.state = {
                cere_id: props.drRevize.cere_id,
                cerp_id: props.drRevize.cere_cerp_id,
                cere_cislo: props.drRevize.cere_cislo,
                cere_datum: Global.dateToObj(props.drRevize.cere_datum),
                cere_poznamka: props.drRevize.cere_poznamka,
                cere_provedeneprace: props.drRevize.cere_provedeneprace,
                cere_old_sn: props.drRevize.cere_old_sn,
                cere_nove_sn: props.drRevize.cere_nove_sn,
                cere_typ: props.drRevize.cere_typ,
                cere_soubor_nazev: props.drRevize.cere_soubor_nazev,
                cere_soubor_type: props.drRevize.cere_soubor_type,

                bZmenaSN: ("" + props.drRevize.cere_nove_sn != ""),

                bNovy: false,
                cere_soubor_base64: null,

                bCerpZmenaRevize: false,
                cerp_datumposlednirevize: Global.dateToObj(props.vybraneZarizeniDetail.cerpadlo.cerp_datumposlednirevize),
            };
        else this.state = {
            cere_id: -1,
            cerp_id: props.vybraneZarizeniDetail.cerpadlo.cerp_id,
            cere_cislo: "",
            cere_datum: Global.dateToObj(Global.toISOString(new Date())),
            cere_poznamka: "",
            cere_provedeneprace: "",
            cere_old_sn: props.vybraneZarizeniDetail.cerpadlo.cerp_sn_venkovni,
            cere_nove_sn: "",
            cere_typ: "SK",
            cere_soubor_nazev: "",
            cere_soubor_type: "",

            bZmenaSN: false,

            bNovy: true,
            cere_soubor_base64: null,

            bCerpZmenaRevize: true,
            cerp_datumposlednirevize: Global.dateToObj(props.vybraneZarizeniDetail.cerpadlo.cerp_datumposlednirevize),
        };

        this.formSubmit = this.formSubmit.bind(this);
        this.zavritDialog = this.zavritDialog.bind(this);
        this.ulozitDataDoDB = this.ulozitDataDoDB.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.smazatZaznam = this.smazatZaznam.bind(this);

    }

    smazatZaznam = async (e, cell) => {
        e.preventDefault();


        const result = await MessageBox.open({
            title: "Dotaz",
            content: <p>Opravdu chcete vybraný servisní záznam smazat?</p>,
            buttons: [
                { name: "Ano", handler: () => "ano" },
                { name: "Ne", handler: () => "ne" }
            ]
        });

        if (result == "ano") {
            LoadingSpinner.open();
            fetchWrapper.del(fetchWrapper.VratCerpadlaRevizeID(this.props.drRevize.cere_id))
                .then(data => {
                    const index = this.props.vybraneZarizeniDetail.revize.indexOf(this.props.drRevize);
                    if (index > -1) {
                        this.props.vybraneZarizeniDetail.revize.splice(index, 1);
                    }

                    LoadingSpinner.close();

                    this.props.zavritDialog(true);
                })
                .catch(error => {
                    LoadingSpinner.close();

                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se zpracovat data:<br /> {error.message}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
    }

    /*componentDidMount() {
        this.inicializace();

        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }

    reloadData() {
        this.inicializace();
    }

    inicializace() {

        this.setState({ loading: true });
        fetchWrapper.get(fetchWrapper.VratZakazniky())
            .then(data => {

            })
            .catch(error => {
                this.setState({ loading: false });

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst číselník: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
    }*/

    formSubmit(event) {
        event.preventDefault();

        this.ulozitDataDoDB();

    }

    // On file select (from the pop up)
    onFileChange = (event) => {
        const f = event.target.files[0];

        if (f) {          
            this.setState({
                cere_soubor_nazev: f.name,
                cere_soubor_type: f.type
            });

            var reader = new FileReader();
            reader.readAsDataURL(f);
            const refInstance = this;
            reader.onload = function () {
                refInstance.setState({
                    cere_soubor_base64: reader.result
                });                
            };
        }
        else
        {
            this.setState({
                cere_soubor_base64: null,
                cere_soubor_nazev: "",
                cere_soubor_type: ""
            });
        }


        /*reader.onerror = function (error) {
            console.log('Error: ', error);
        };*/
    };

    ulozitDataDoDB() {
        let chyba = "";
        if (this.state.cere_soubor_base64 == "" || this.state.cerp_model == "") chyba = chyba + " Nebyl nastaven soubor.";
        if (this.state.bZmenaSN && (this.state.cere_nove_sn == "" || this.state.cere_old_sn == "")) chyba = chyba + " Musí být zadáno S/N staré a S/N nové.";
        if (this.state.bNovy && !(this.state.cere_soubor_base64)) chyba = chyba + " Není vybrán žádný soubor.";


        if (chyba != "") {
            MessageBox.open({
                title: "Chyba",
                content: <p>{chyba}</p>,
                buttons: [
                    { name: "OK", handler: () => "OK" }
                ]
            });

            return;
        }

        

        const cere_old_sn = this.state.bZmenaSN ? this.state.cere_old_sn : "";
        const cere_nove_sn = this.state.bZmenaSN ? this.state.cere_nove_sn : "";

        const revizeData = {
            cere_id: this.state.cere_id,
            cere_cerp_id: this.state.cerp_id,
            cere_cislo: this.state.cere_cislo,
            cere_datum: Global.dateToStr(this.state.cere_datum),
            cere_poznamka: this.state.cere_poznamka,
            cere_provedeneprace: this.state.cere_provedeneprace,
            cere_typ: this.state.cere_typ,
            cere_old_sn: cere_old_sn,
            cere_nove_sn: cere_nove_sn,
            cere_soubor_nazev: this.state.cere_soubor_nazev,
            cere_soubor_type: this.state.cere_soubor_type,
            cere_soubor_base64: this.state.cere_soubor_base64,
            cere_propsat_datum_revize: this.state.bCerpZmenaRevize
        };


        LoadingSpinner.open();
        if (this.state.cere_id > 0) {
            fetchWrapper.put(fetchWrapper.VratCerpadlaRevize(), revizeData).then(data => {
                LoadingSpinner.close();

                this.props.drRevize.cere_cislo = this.state.cere_cislo;
                this.props.drRevize.cere_datum = Global.dateToStr(this.state.cere_datum);

                this.props.drRevize.cere_poznamka = this.state.cere_poznamka;
                this.props.drRevize.cere_provedeneprace = this.state.cere_provedeneprace;
                this.props.drRevize.cere_typ = this.state.cere_typ;
                this.props.drRevize.cere_old_sn = this.state.cere_old_sn;
                this.props.drRevize.cere_nove_sn = this.state.cere_nove_sn;
                this.props.drRevize.cere_soubor_nazev = this.state.cere_soubor_nazev;
                this.props.drRevize.cere_soubor_type = this.state.cere_soubor_type;

                if (this.state.bZmenaSN) this.props.vybraneZarizeniDetail.cerpadlo.cerp_sn_venkovni = this.state.cere_nove_sn;
                if (this.state.bCerpZmenaRevize) this.props.vybraneZarizeniDetail.cerpadlo.cerp_datumposlednirevize = this.state.cere_datum;

                this.props.zavritDialog(true);
            }).catch(error => {
                LoadingSpinner.close();
                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se uložit data: {error.message}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
        }
        else {
            fetchWrapper.post(fetchWrapper.VratCerpadlaRevize(), revizeData).then(data => {
                LoadingSpinner.close();
                revizeData.cere_id = data;
                this.props.vybraneZarizeniDetail.revize.push(revizeData);

                if (this.state.bZmenaSN) this.props.vybraneZarizeniDetail.cerpadlo.cerp_sn_venkovni = this.state.cere_nove_sn;
                if (this.state.bCerpZmenaRevize) this.props.vybraneZarizeniDetail.cerpadlo.cerp_datumposlednirevize = this.state.cere_datum;

                this.props.zavritDialog(true);
            })
                .catch(error => {
                    LoadingSpinner.close();
                    MessageBox.open({
                        title: "Chyba",
                        content: <p>Nepodařilo se uložit data: {error.message}</p>,
                        buttons: [
                            { name: "OK", handler: () => "OK" }
                        ]
                    });
                });
        }
    }

    zavritDialog() {
        this.props.zavritDialog(false);
    }



    render() {
        const nadpis = (this.state.bNovy ? "Nový servisní záznam" : "Upravit servisní záznam");

        console.log(this.state.cere_typ);
        return (
            <ThemeProvider theme={MuiTheme}>
                <LocalizationProvider adapterLocale={cs} dateAdapter={AdapterDateFns}>
                    <form onSubmit={this.formSubmit}>
                        <h1>{nadpis}
                            {this.state.cere_id > 0 && <button className="form_button" style={{ marginLeft: 30 }} onClick={this.smazatZaznam}>Smazat</button>}
                        </h1>
                        <div className="form_udaj_container">
                            <EditorHodnotyForm popis="Typ:" hodnota={this.state.cere_typ} widthLeft={160} stylesRight={{ maxWidth: 300 }} typ="TYPREVIZE" onChange={e => this.setState({ cere_typ: e.target.value })} />
                        </div>
                        {this.state.cere_typ == 'SK' &&
                            <div className="form_udaj_container">
                                <EditorHodnotyForm popis="&nbsp;" popisRight={"Aktualizovat datum posl.rev. u čerpadla (" + Global.dateDBFormatToDateStrBezCasu(this.state.cerp_datumposlednirevize) + ")"} popisLineHeight={16} hodnota={this.state.bCerpZmenaRevize} widthLeft={160} stylesRight={{ maxWidth: 500 }} typ="CHECKBOX" onChange={e => this.setState({ bCerpZmenaRevize: e.target.checked ? true : false })} />
                                
                            </div>
                        }

                        <div className="form_udaj_container">
                            <EditorHodnotyForm popis="Datum vzniku:" hodnota={this.state.cere_datum} widthLeft={160} stylesRight={{ maxWidth: 200 }} typ="DATE" onChange={val => this.setState({ cere_datum: val })} />
                        </div>

                        <div className="form_udaj_container odsazeni_top10">
                            <EditorHodnotyForm popis="Změna S/N" hodnota={this.state.bZmenaSN} widthLeft={160} stylesRight={{ maxWidth: 100 }} typ="CHECKBOX" onChange={e => this.setState({ bZmenaSN: e.target.checked ? true : false })} />
                        </div>

                        {this.state.bZmenaSN == 1 &&
                            <>
                                <div className="form_udaj_container">
                                <EditorHodnotyForm popis="S/N původní:" hodnota={this.state.cere_old_sn} widthLeft={160} stylesRight={{ maxWidth: 250 }} typ="TEXT" onChange={e => this.setState({ cere_old_sn: e.target.value })} />
                                </div>
                                <div className="form_udaj_container">
                                    <EditorHodnotyForm popis="S/N nové:" hodnota={this.state.cere_nove_sn} widthLeft={160} stylesRight={{ maxWidth: 250 }} typ="TEXT" onChange={e => this.setState({ cere_nove_sn: e.target.value })} />
                                </div>
                            </>
                        }


                        <div className="odsazeni_top10">
                            <div className="form_udaj_container">
                                <EditorHodnotyForm popis="Poznámka:" hodnota={this.state.cere_poznamka} widthLeft={160} stylesRight={{ maxWidth: 500 }} typ="TEXTAREA" onChange={e => this.setState({ cere_poznamka: e.target.value })} />
                            </div>
                        </div>

                        <div className="odsazeni_top10">
                            <div className="form_udaj_container" style={{fontWeight:'bold'}}>
                                <div className="left" style={{ width: 160 }}>
                                    Soubor:
                                </div>
                                <div className="right">
                                <label for="file-upload" className={styles.custom_file_upload}>
                                    Vybrat
                                    </label>
                                
                                <input
                                    type="file"
                                    id="file-upload"
                                    onChange={this.onFileChange}
                                    /> {this.state.cere_soubor_nazev}            
                                </div>
                            </div>
                        </div>


                        <div className="form_udaj_container">
                            <div className="left" style={{ width: 120 }}> </div>
                            <div className="right">
                                <button type="button" className="form_button" style={{ marginLeft: 7 }} onClick={this.zavritDialog}>Zpět</button>
                                <button type="submit" className="form_button" style={{ marginLeft: 7 }}>Uložit</button>
                            </div>
                        </div>
                    </form>
                </LocalizationProvider>
            </ThemeProvider>
        );
    }
}
import React from 'react';
import { ReactTabulator } from 'react-tabulator';
import { reactFormatter } from 'react-tabulator/lib/Utils.js?version=4.0';
import styles from './ZakaznikDetailZarizeni.module.css'

import { ObjektDetailHodnotaRadek } from "./ObjektDetailHodnotaRadek";

import { CameraFeed } from './CameraFeed';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { cs } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { MuiTheme } from "./MuiTheme";

import { fetchWrapper } from "../FetchWrapper";
import AuthService from "../AuthService"
import CacheData from "../CacheData"


import { Global } from './Global';
import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";

import { format } from 'date-fns'


const tabServisZaznamy = [
    {
        title: 'Typ záznamu', width: 200, headerSort: true
    },
    {
        field: 'seza_typ', title: 'Typ', width: 200, headerSort: true, formatter: function (cell, formatterParams, onRendered) {
            if (cell.getValue() == 0) return "Instalace";
            else if (cell.getValue() == 1) return "Revize";
            else if (cell.getValue() == 1) return "Oprava";            
            else return "Jiné";
        }
    },
    { field: 'seza_datum', title: 'Datum', width: 100, sorter: "number", headerSort: true }
];


export class ZarizeniDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            zobrazenyPanel: "DETAIL", detailSekce: "VLASTNOSTI", servisZarizeniZaznamy: []
        };
        
        this.zavritDialogZaznam = this.zavritDialogZaznam.bind(this);
    }

    zpracovatDataAdresyZMapy() {

    }

    zavritDialogZaznam(bZmena) {
        if (bZmena) {

        }

        this.setState({ zobrazenyPanel: "DETAIL" });
    }

    componentDidMount() {
        this.setState({ mistoAdresa: this.props.drZarizeni.cerp_ulice + ", " + this.props.drZarizeni.cerp_psc + " " + this.props.drZarizeni.cerp_mesto })
    }



    render() {
        return (
            <div>                                
                <div className="odsazeni_bottom10" style={{ minHeight: 500, display: this.state.zobrazenyPanel === "DETAIL" ? 'block' : 'none' }}>
                    <div className="grid_12" onContextMenu={(e) => e.preventDefault()}>
                        <h1>Vybrané zařízení                           
                        </h1>



                        <ThemeProvider theme={MuiTheme}>
                            <LocalizationProvider adapterLocale={cs} dateAdapter={AdapterDateFns}>
                                <div className="odsazeni_bottom10">
                                    <div className="grid_5" >
                                        <div className="box round" style={{ minHeight: 420 }}>
                                            <h2>Základní údaje</h2>
                                            <div className="grid_12">
                                                <ObjektDetailHodnotaRadek popis="Značka:" widthLeft={130} hodnota={this.props.drZarizeni.cerp_znacka} />
                                                <ObjektDetailHodnotaRadek popis="Model:" widthLeft={130} hodnota={this.props.drZarizeni.cerp_model} />
                                                <ObjektDetailHodnotaRadek popis="Typ:" widthLeft={130} hodnota={Global.EnumCerpadloTyp(this.props.drZarizeni.cerp_typ)}/>
                                                <ObjektDetailHodnotaRadek popis="Výr.číslo:" widthLeft={130} hodnota={this.props.drZarizeni.cerp_sn} />
                                                <ObjektDetailHodnotaRadek popis="Rok výroby:" widthLeft={130} hodnota={this.props.drZarizeni.cerp_rokvyroby} />
                                                <ObjektDetailHodnotaRadek popis="Chladivo:" widthLeft={130} hodnota={this.props.drZarizeni.cerp_chladivo} />                                                    
                                            </div>
                                            <div className="clear" />
                                            <h3>Místo instalace</h3>
                                            <div className="grid_12">
                                                <ObjektDetailHodnotaRadek popis="Ulice:" widthLeft={130} hodnota={this.props.drZarizeni.cerp_ulice} />
                                                <ObjektDetailHodnotaRadek popis="Město:" widthLeft={130} hodnota={this.props.drZarizeni.cerp_mesto} />
                                                <ObjektDetailHodnotaRadek popis="PSČ:" widthLeft={130} hodnota={this.props.drZarizeni.cerp_psc} />
                                                <div className="odsazeni_bottom10 odsazeni_top10"> </div>
                                                <ObjektDetailHodnotaRadek popis="Datum instalace:" widthLeft={130} hodnota={Global.dateDBFormatToDateStr(this.props.drZarizeni.cerp_datuminstalace)} />
                                                <ObjektDetailHodnotaRadek popis="Instaloval:" widthLeft={130} hodnota={this.props.drZarizeni.cerp_instaloval} />
                                                <ObjektDetailHodnotaRadek popis="Pravidelná revize:" widthLeft={130} hodnota={this.props.drZarizeni.cerp_pravidelnarevize ? "ANO" : "NE"} />
                                                <ObjektDetailHodnotaRadek popis="Datum posl.revize:" widthLeft={130} hodnota={Global.dateDBFormatToDateStr(this.props.drZarizeni.cerp_datumposlednirevize)} />
                                            </div>
                                            <div className="clear" /> 

                                            {this.props.drZarizeni.cerp_zruseno == 1 &&
                                                <div style={{ color: "red",  fontSize: 20, fontWeight: "bold"}}><br/>Zrušeno {Global.dateDBFormatToDateStr(this.props.drZarizeni.cerp_zrusenodatum)}</div>
                                            }
                                        </div>
                                    </div>
                                    <div className="grid_7">
                                        <div className="box round" style={{ height: 420 }}>
                                            <h2>Zákazník</h2>
                                            <div className="grid_12">
                                                <ObjektDetailHodnotaRadek popis="Jméno:" widthLeft={130} hodnota={this.props.drZakaznik.seza_jmeno} />
                                                <ObjektDetailHodnotaRadek popis="Ulice:" widthLeft={130} hodnota={this.props.drZakaznik.seza_ulice} />
                                                <ObjektDetailHodnotaRadek popis="Město:" widthLeft={130} hodnota={this.props.drZakaznik.seza_mesto} />
                                                <ObjektDetailHodnotaRadek popis="PSČ:" widthLeft={130} hodnota={this.props.drZakaznik.seza_psc} />

                                                <br />
                                                <ObjektDetailHodnotaRadek popis="Telefon:" widthLeft={130} hodnota={this.props.drZakaznik.seza_telefon} />


                                                <ObjektDetailHodnotaRadek popis="Email:" widthLeft={130} hodnota={this.props.drZakaznik.seza_email} />
                                                <br />

                                                <ObjektDetailHodnotaRadek popis="Datum přidání:" hodnota={Global.toDateStr(new Date(this.props.drZakaznik.seza_datumpridani))} widthLeft={130} />
                                            </div>
                                            <div className="clear" />


                                            <br />
                                            {this.props.drZakaznik.seza_po == 1 &&
                                                <>
                                                <ObjektDetailHodnotaRadek popis="IČ:" widthLeft={130} hodnota={this.props.drZakaznik.seza_ic} />
                                                <ObjektDetailHodnotaRadek popis="DIČ:" widthLeft={130} hodnota={this.props.drZakaznik.seza_dic} />
                                                <ObjektDetailHodnotaRadek popis="Kontaktní osoba:" widthLeft={130} hodnota={this.props.drZakaznik.seza_kontakt} />

                                                </>
                                            }
                                            <ObjektDetailHodnotaRadek popis="Poznámka:" typ="TEXTAREA" hodnota={this.props.drZakaznik.seza_poznamka} widthLeft={130} />

                                            <div className="clear" />
                                        </div>
                                    </div>

                                    <div className="clear" />

                                    <div className="grid_12" >
                                        <h2>Poznámka</h2>
                                        <ObjektDetailHodnotaRadek popis="" typ="TEXTAREA" hodnota={this.props.drZarizeni.cerp_poznamka} widthLeft={0} />
                                    </div>

                                    <div className="grid_12" onContextMenu={(e) => e.preventDefault()}>
                                        <h2>Záznamy servisu</h2>
                                        <ReactTabulator
                                            data={this.state.servisZarizeniZaznamy}
                                            columns={tabServisZaznamy}
                                            layout={'fitDataStretch'}
                                            height={'200px'}
                                            events={{ rowClick: this.zarizeniVyber }}
                                            initialSort={[{ column: "seza_datum", dir: "asc" }]}
                                        />
                                    </div>

                                    <div className="grid_12" onContextMenu={(e) => e.preventDefault()}>
                                        <h2>Fotodokumentace</h2>
                                    </div>

                                    <div className="clear" />
                                </div>
                            </LocalizationProvider>
                        </ThemeProvider> 
                    </div>
                    <div className="clear" />


                </div>

            </div >);
    }
}

import React from 'react';
import { ZakaznikDetail } from './ZakaznikDetail';
import { ZakazniciList } from "./ZakazniciList";

import { fetchWrapper } from "../FetchWrapper";
import AuthService from "../AuthService"
import CacheData from "../CacheData"

import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";


export class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = { dataZakaznici: [], detailZakaznik: null };

        this.zavritDetailEvent = this.zavritDetailEvent.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.vyberDetailZakaznik = this.vyberDetailZakaznik.bind(this);
    }

    componentDidMount() {
        window.addEventListener('reloadData', this.reloadData);
    }

    componentWillUnmount() {
        window.removeEventListener('reloadData', this.reloadData)
    }

    reloadData() {
        let zaka_idVychozi = -1;
        const detailZakaznikVychozi = CacheData.GetData(AuthService.getCurrentUser(), "servisZakaznik");
        if (detailZakaznikVychozi) zaka_idVychozi = detailZakaznikVychozi.zaka_id;

        if (this.state.detailZakaznik) {
            if (zaka_idVychozi == this.state.detailZakaznik.zaka_id) return;

            this.vyberDetailZakaznik(this.state.detailZakaznik);
        }
    }

    zavritDetailEvent() {
        this.setState({ detailZakaznik: null });
    }

    vyberDetailZakaznik(drZaka) {
        LoadingSpinner.open();

        fetchWrapper.get(fetchWrapper.VratDetailZakaznik(drZaka.zaka_id))
            .then(data => {
                LoadingSpinner.close(); this.setState({ detailZakaznik: data }) })
            .catch(error => {
                LoadingSpinner.close();
                this.setState({ detailZakaznik: null });

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst data: {error}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            }); 
    }

    render() {
        let zaka_idVychozi = -1; 
        const detaiZakaznikVychozi = CacheData.GetData(AuthService.getCurrentUser(), "servisZakaznik");
        if (detaiZakaznikVychozi) zaka_idVychozi = detaiZakaznikVychozi.sevy_id;

        
        
        return (
            <>
                <div className="grid_12 odsazeni_bottom50" style={{ display: this.state.detailZakaznik ? 'none' : 'block' }}>
                    <ZakazniciList vyberDetail={this.vyberDetailZakaznik} />
                </div>

                {this.state.detailZakaznik && <ZakaznikDetail navigate={this.props.navigate} sevyData={this.state.detailZakaznik} zavritDetailEvent={this.zavritDetailEvent} bDetailVychozi={zaka_idVychozi == this.state.detailZakaznik.zaka_id} bTlacitkoZpet={true} />}
            </>
        );
    }
}




import AuthService from './AuthService';
import Global from './components/Global';


export const CacheData = {
    SetData,
    GetData,
    DeleteData,

    SetSessionData,
    GetSessionData,
    DeleteSessionData,
};

export default CacheData;

function GetData(user, klic) {

    if (user) klic = klic + "__" + user.uziv_id;
    const dataObjekt = localStorage.getItem(klic);

    if (dataObjekt == null) return null;

    try {
        return JSON.parse(dataObjekt);
    }
    catch
    {
        return null;
    }
}

function SetData(user, klic, dataObjekt) {
    if (user) {
        localStorage.setItem(klic + "__" + user.uziv_id, JSON.stringify(dataObjekt));
    }
    else localStorage.setItem(klic, JSON.stringify(dataObjekt));
}

function DeleteData(user, klic) {
    if (user) {
        localStorage.removeItem(klic + "__" + user.uziv_id);
    }
    else localStorage.removeItem(klic);
}




function GetSessionData(user, klic) {

    if (user) klic = klic + "__" + user.uziv_id;
    const dataObjekt = sessionStorage.getItem(klic);

    if (dataObjekt == null) return null;

    try {
        return JSON.parse(dataObjekt);
    }
    catch
    {
        return null;
    }
}

function SetSessionData(user, klic, dataObjekt) {
    if (user) {
        sessionStorage.setItem(klic + "__" + user.uziv_id, JSON.stringify(dataObjekt));
    }
    else sessionStorage.setItem(klic, JSON.stringify(dataObjekt));
}

function DeleteSessionData(user, klic) {
    if (user) {
        sessionStorage.removeItem(klic + "__" + user.uziv_id);
    }
    else sessionStorage.removeItem(klic);
}
import React from 'react';

export class CameraFeed extends React.Component {
    constructor(props) {
        super(props);

        this.state = { bKameraOK: false, rezim: "KAMERA", popis: "foto" }
        this.canvas = React.createRef();
        this.videoPlayer = React.createRef();

        this.processDevices = this.processDevices.bind(this);
        this.takePhoto = this.takePhoto.bind(this);

        this.zahoditFoto = this.zahoditFoto.bind(this);
        this.nahratFoto = this.nahratFoto.bind(this);
    }
    


    /**
     * Processes available devices and identifies one by the label
     * @memberof CameraFeed
     * @instance
     */
    processDevices(devices) {
        devices.forEach(device => {
            console.log(device.label);
            this.setDevice(device);
        });
    }

    /**
     * Sets the active device and starts playing the feed
     * @memberof CameraFeed
     * @instance
     */
    async setDevice(device) {
        const { deviceId } = device;
        const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: { deviceId } });
        console.log(stream);
        this.videoPlayer.srcObject = stream;
        this.videoPlayer.play();
        this.setState({ bKameraOK: true });
    }

    /**
     * On mount, grab the users connected devices and process them
     * @memberof CameraFeed
     * @instance
     * @override
     */
    async componentDidMount() {
        const cameras = await navigator.mediaDevices.enumerateDevices();
        this.processDevices(cameras);
    }

    /**
     * Handles taking a still image from the video feed on the camera
     * @memberof CameraFeed
     * @instance
     */
    takePhoto() {
        this.setState({ rezim: "FOTO" });
        this.canvas.width = this.videoPlayer.videoWidth;
        this.canvas.height = this.videoPlayer.videoHeight;

        const context = this.canvas.getContext('2d');
        context.drawImage(this.videoPlayer, 0, 0, this.videoPlayer.videoWidth, this.videoPlayer.videoHeight);
    };

    zahoditFoto () {
        this.setState({ rezim: "KAMERA" });
    }

    nahratFoto() {
        const foto = this.canvas.toDataURL('image/png');
        this.props.noveFoto(foto, this.state.popis);
        this.setState({ rezim: "KAMERA" });
    };

    render() {
        console.log(this.state);
        return (  
            <>
                <div className="c-camera-feed grid_12" style={{ marginTop: 10 }}>

                    <div style={{ display: (this.state.bKameraOK ? "block" : "none") }}>
                            <div className="c-camera-feed__viewer" style={{ maxWidth: 800,display: (this.state.rezim == "KAMERA") ? 'block' : 'none' }}>
                                <video ref={ref => (this.videoPlayer = ref)} width="100%" heigh="360" onClick={this.takePhoto} />
                                <div className="clear" />
                                <div style={{ marginTop: 10 }}>
                                    <button onClick={this.takePhoto} className="form_button">Vyfotit</button>
                                </div>
                            </div>
                    </div>

                    <div className="c-camera-feed__stage" style={{ display: (this.state.rezim == "FOTO") ? 'block' : 'none' }}>
                        <canvas width="640" height="480" ref={(ref) => { this.canvas = ref }} />
                                <div className="clear" />

                                <div style={{ marginTop: 10 }}>
                                    Popis: <input type="text" value={this.state.popis} onChange={e => this.setState({ popis: e.target.value })} style={{ marginRight: 10 }} />
                                    <button onClick={this.nahratFoto} className="form_button" style={{ marginRight: 30 }}>Uložit foto</button>
                                    <button onClick={this.zahoditFoto} className="form_button">Zahodit</button>
                                </div>
                            </div>
                        
                     </div>
                    { !this.state.bKameraOK && <div className="error_zprava">Kamera nebyla nalezena.</div> }
            </>
        );
    }
}
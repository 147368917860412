import React from 'react';
import { ReactTabulator } from 'react-tabulator';
import { reactFormatter } from 'react-tabulator/lib/Utils.js?version=4.0';
import styles from './ZakaznikDetail.module.css'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { cs } from 'date-fns/locale'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { MuiTheme } from "./MuiTheme";

import { ZakaznikDetailZarizeni } from "./ZakaznikDetailZarizeni";
import { ZakazniciForm } from "./ZakazniciForm";
import { CerpadloForm } from "./CerpadloForm";
import { ObjektDetailHodnotaRadek } from "./ObjektDetailHodnotaRadek";


import AuthService from "../AuthService";
import CacheData from "../CacheData";
import { fetchWrapper } from "../FetchWrapper";


import { Global } from "./Global";

import MessageBox from "./MessageBox/MessageBox";
import LoadingSpinner from "./Loader/LoadingSpinner";




const tabCerpadla = [
    {
        field: 'cerp_znacka', title: 'Značka', width: 200, headerSort: true, resizable: true, /*formatter: function (cell, formatterParams, onRendered) {
            if (cell.getValue() == 0) return "Ventilátor";
            else return "Jiné";
        }*/
    },
    { field: 'cerp_model', title: 'Model', width: 200, sorter: "number", headerSort: true },
    {
        field: 'cerp_typ', title: 'Typ', width: 100, headerSort: true, resizable: true, formatter: function (cell, formatterParams, onRendered) {
            if (cell.getValue() == 0) return "Monoblok";
            else if  (cell.getValue() == 1) return "Split";
            else return "Jiné";
        }
    },    
    { field: 'cerp_sn_venkovni', title: 'S/N (venkovní)', width: 120, headerSort: true },
    {
        field: 'cerp_zruseno', title: 'Stav', width: 100, headerSort: true, resizable: true, formatter: function (cell, formatterParams, onRendered) {
            if (cell.getValue() == 0) return "V běhu";
            else return "Zrušeno";
        }
    },
    {
        field: 'cerp_datuminstalace', title: 'Datum instalace', width: 140, headerSort: true, resizable: true, formatter: function (cell, formatterParams, onRendered) {

            return Global.toDateStr(new Date(cell.getValue()));
        } },
    {
        field: 'cerp_datumposlednirevize', title: 'Datum poslední revize', width: 100, headerSort: true, resizable: true, formatter: function (cell, formatterParams, onRendered) {

            return Global.toDateStr(new Date(cell.getValue()));
        } },
];


export class ZakaznikDetail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            zobrazenyPanel: "DETAIL",
            vybraneZarizeniDetail: null,
            mistoAdresa: "",
            zmenaUdajuZakaznika: false
        };

        this.novyZaznamCerpadlo = this.novyZaznamCerpadlo.bind(this);
        this.zavritDetailZakaznikaEvent = this.zavritDetailZakaznikaEvent.bind(this);
        this.zarizeniVyber = this.zarizeniVyber.bind(this);
        this.zavritDetailZarizeniEvent = this.zavritDetailZarizeniEvent.bind(this);
        this.upravitZakaznika = this.upravitZakaznika.bind(this);
        this.zavritDialogZakazniciForm = this.zavritDialogZakazniciForm.bind(this);
    }

    componentDidMount() {
        
    }

    componentWillUnmount() {
       
    }

    novyZaznamCerpadlo() {
        this.setState({ zobrazenyPanel: "FORMZARIZENI" });

    }

    zavritDialogZakazniciForm(bZmena) {
        if (bZmena) {
            this.state.zmenaUdajuZakaznika = true;
        }

        this.setState({ zobrazenyPanel: 'DETAIL'})
    }

    zavritDetailZakaznikaEvent(event) {
        event.preventDefault();
        this.props.zavritDetail(this.state.zmenaUdajuZakaznika);
    }

    zarizeniVyber = (e, row) => {

        fetchWrapper.get(fetchWrapper.VratCerpadloDetail(row.getData().cerp_id))
            .then(data => {
                LoadingSpinner.close();
                data.cerpadlo = row.getData(); //nastaveni instance pouzivane instance cerpadla
                this.setState({ zobrazenyPanel: "DETAILZARIZENI", vybraneZarizeniDetail: data });
            })
            .catch(error => {
                LoadingSpinner.close();

                MessageBox.open({
                    title: "Chyba",
                    content: <p>Nepodařilo se načíst data: {error.message}</p>,
                    buttons: [
                        { name: "OK", handler: () => "OK" }
                    ]
                });
            });
               
        //window.scrollTo(0, 0);
    }


    zavritDetailZarizeniEvent() {
        this.setState({ zobrazenyPanel: 'DETAIL', vybraneZarizeniDetail: null })
    }


    upravitZakaznika() {
        this.setState({ zobrazenyPanel: "FORMZAKAZNIK" });
    }



    render() {
        const user = AuthService.getCurrentUser();

        return (
            <>
                {this.props.vybranyZakaznikDetail &&
                    <div>
                        {this.state.zobrazenyPanel == "DETAIL" &&
                        <div>
                            <div className="grid_12">                                
                                    <div>
                                        {<h1>Detail - {this.props.vybranyZakaznikDetail.zakaznik.seza_jmeno}
                                            {this.props.bTlacitkoZpet && <button className="form_button" style={{ marginLeft: 30 }} onClick={this.zavritDetailZakaznikaEvent} >Zpět</button>} 
                                            <button className="form_button" style={{ marginLeft: 30 }} onClick={this.upravitZakaznika} >Upravit</button>
                                        </h1>}
                                    </div>
                            </div>
                            <div className="clear" />


                            <ThemeProvider theme={MuiTheme}>
                                    <LocalizationProvider adapterLocale={cs} dateAdapter={AdapterDateFns}>
                                        <div className="odsazeni_bottom10">

                                            <div className="grid_5" >
                                                <div className="box round" style={{ minHeight: 300 }}>
                                                    <h2>Základní údaje</h2>
                                                    <div className="grid_12">
                                                        <ObjektDetailHodnotaRadek popis="Jméno:" widthLeft={130} hodnota={this.props.vybranyZakaznikDetail.zakaznik.seza_jmeno}  />
                                                        <ObjektDetailHodnotaRadek popis="Ulice:" widthLeft={130} hodnota={this.props.vybranyZakaznikDetail.zakaznik.seza_ulice}  />
                                                        <ObjektDetailHodnotaRadek popis="Město:" widthLeft={130} hodnota={this.props.vybranyZakaznikDetail.zakaznik.seza_mesto} />
                                                        <ObjektDetailHodnotaRadek popis="PSČ:" widthLeft={130} hodnota={this.props.vybranyZakaznikDetail.zakaznik.seza_psc}  />

                                                        <br/>
                                                        <ObjektDetailHodnotaRadek popis="Telefon:" widthLeft={130} hodnota={this.props.vybranyZakaznikDetail.zakaznik.seza_telefon} />
                                                        

                                                        <ObjektDetailHodnotaRadek popis="Email:" widthLeft={130} hodnota={this.props.vybranyZakaznikDetail.zakaznik.seza_email} />
                                                        <br />

                                                        
                                                    </div>
                                                    <div className="clear" />


                                                    <br />
                                                    {this.props.vybranyZakaznikDetail.zakaznik.seza_po == 1 &&
                                                        <>
                                                            <ObjektDetailHodnotaRadek popis="IČ:" widthLeft={130} hodnota={this.props.vybranyZakaznikDetail.zakaznik.seza_ic} />
                                                            <ObjektDetailHodnotaRadek popis="DIČ:" widthLeft={130} hodnota={this.props.vybranyZakaznikDetail.zakaznik.seza_dic} />
                                                            <ObjektDetailHodnotaRadek popis="Kontaktní osoba:" widthLeft={130} hodnota={this.props.vybranyZakaznikDetail.zakaznik.seza_kontakt} />

                                                        </>
                                                    }
                                                    
                                                    <div className="clear" />
                                                    <br />
                                                </div>

                                            </div>
                                            <div className="grid_7">
                                                <div className="box round" style={{ minHeight: 300 }}>
                                                    <h2>Další údaje</h2>
                                                        <ObjektDetailHodnotaRadek popis="Datum přidání:" hodnota={Global.toDateStr(new Date(this.props.vybranyZakaznikDetail.zakaznik.seza_datumpridani))} widthLeft={130} />
                                                        <br />
                                                        <ObjektDetailHodnotaRadek popis="Poznámka:" typ="TEXTAREA" hodnota={this.props.vybranyZakaznikDetail.zakaznik.seza_poznamka} widthLeft={130} />


                                                    </div>
                                            </div>

                                            <div className="clear" />
                                                    
                                            
                                            <div className="grid_12" onContextMenu={(e) => e.preventDefault()}>
                                                <h2>Servisovaná zařízení <button className="form_button" style={{ marginLeft: 20 }} onClick={this.novyZaznamCerpadlo}><img src="./img/new.png"></img></button></h2>
                                                <ReactTabulator
                                                    data={this.props.vybranyZakaznikDetail.cerpadla}
                                                    columns={tabCerpadla}
                                                    layout={'fitDataStretch'}
                                                    height={'300px'}
                                                    events={{ rowClick: this.zarizeniVyber }}
                                                    initialSort={[{ column: "cerp_sn_venkovni", dir: "asc" }]}
                                                />
                                            </div>

                                            <div className="clear" />
                                        </div>
                                    </LocalizationProvider>
                                </ThemeProvider>                           
                            </div>
                        }
                        {this.state.zobrazenyPanel == 'FORMZAKAZNIK' && <ZakazniciForm drZakaznik={this.props.vybranyZakaznikDetail.zakaznik} zavritDialog={this.zavritDialogZakazniciForm} />}
                        {this.state.zobrazenyPanel == 'FORMZARIZENI' && <CerpadloForm vybranyZakaznikDetail={this.props.vybranyZakaznikDetail} zavritDialog={this.zavritDialogZakazniciForm} />}


                        {this.state.zobrazenyPanel == "DETAILZARIZENI" &&
                            <ZakaznikDetailZarizeni bAkce={this.props.bDetailVychozi} vybranyZakaznikDetail={this.props.vybranyZakaznikDetail} vybraneZarizeniDetail={this.state.vybraneZarizeniDetail} zavritDetail={this.zavritDetailZarizeniEvent} />}
                    </div>
                }
            </>
        );
    }
}




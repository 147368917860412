import Global from "./Global";
import { API_URL } from "../FetchWrapper";


      export function Footer() {
        return (        
          <div className="site_info">
                <p>
                    v{window.KLIENT_VERZE}                  
                </p>
                <div className="site_info_icon8">
                    Icons by <a target="_blank" href="https://icons8.com">Icons8</a>
                    <br /> API {API_URL}
                </div>
          </div>
        );
      }